import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import { ChevronRight } from "lucide-react";
import styled from "styled-components";

const Container = styled.div`
  && {
    background: #ffffff;
    padding: 0;
    margin: 0 0 32px 0;
    position: relative;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 3px 16px -6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s ease-out;
    overflow: hidden;

    .tile-img-frame {
      border-bottom: 1px solid var(--gray10);
      transition: border-bottom 0.1s ease-out;
    }

    .tile-desc-ctn {
      background: #ffffff;

      .tile-subtitle {
        color: var(--gray60);
        transition: color 0.1s ease-out;
      }

      svg {
        color: var(--gray60);
        transform: translateX(0);
        transition:
          color 0.1s ease-out,
          transform 0.1s ease-out;
      }
    }

    &:hover,
    &:focus {
      box-shadow:
        0px 0px 0px 5px rgba(223, 225, 228, 0.25),
        0px 6px 30px -2px rgba(0, 0, 0, 0.12);

      .tile-img-frame {
        border-bottom: 1px solid var(--gray30);
      }

      .tile-desc-ctn {
        .tile-subtitle {
          color: var(--gray70);
        }

        svg {
          color: var(--gray70);
          transform: translateX(2px);
        }
      }
    }
  }
`;

const DescriptionContainer = styled.div.attrs((props) => ({
  className: classNames("tile-desc-ctn", props.className),
}))`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

const TitleCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

const Title = styled.h5`
  && {
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: var(--gray90);
    margin: 0 0 2px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Subtitle = styled.h6.attrs({ className: "tile-subtitle" })`
  &&& {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--gray60);
    margin: 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ImgFrame = styled.div.attrs({ className: "tile-img-frame" })`
  height: 170px;
  background: #f9fafc;
  overflow: hidden;
  position: relative;
`;

type DescriptionProps = Pick<React.ComponentProps<"div">, "className"> & {
  title: string;
  subtitle?: string;
  showChevron?: boolean;
};

const Description = ({ title, subtitle, showChevron, className }: DescriptionProps) => (
  <DescriptionContainer className={className}>
    <TitleCtn>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleCtn>
    {showChevron && <ChevronRight size="16px" />}
  </DescriptionContainer>
);

type TileProps = Pick<React.ComponentProps<"div">, "className"> & {
  to: string;
  title: string;
  subtitle?: string;
};

export const Tile = ({ to, title, subtitle, className }: TileProps) => (
  <Link to={to}>
    <Container className={className}>
      <Description title={title} subtitle={subtitle} />
    </Container>
  </Link>
);

export interface TileWithImageProps {
  to: string;
  img: React.ReactElement;
  title: string;
  subtitle?: string;
  showChevron?: boolean;
}

export const TileWithImage = ({
  to,
  img,
  title,
  subtitle,
  showChevron = true,
}: TileWithImageProps) => (
  <Link to={to}>
    <Container>
      <ImgFrame>{img}</ImgFrame>
      <Description title={title} subtitle={subtitle} showChevron={showChevron} />
    </Container>
  </Link>
);

const NavigationTileDescription = styled(Description)`
  /* Override StaticFancyPageContainer PaddedRow */
  && {
    ${Title}, ${Subtitle} {
      margin: 0;
    }
  }
`;

export type NavigationTileWithIconProps<T> = TileWithImageProps & {
  activeClassName?: string;
};

export const NavigationTileWithIcon = <T,>({
  to,
  img,
  title,
  subtitle,
  showChevron = true,
  activeClassName,
}: NavigationTileWithIconProps<T>) => (
  <Link
    to={to}
    title={`${title}${subtitle ? ` - ${subtitle}` : ""}`}
    activeClassName={activeClassName}
  >
    <Container className="d-flex align-items-center">
      {img}
      <NavigationTileDescription title={title} subtitle={subtitle} showChevron={showChevron} />
    </Container>
  </Link>
);

export type ActiveStyledNavigationTileWithIconProps = Pick<
  React.ComponentProps<"div">,
  "className"
> &
  TileWithImageProps;

/**
 * Forwards the className prop as activeClassName.
 */
export const ActiveStyledNavigationTileWithIcon = <T,>({
  showChevron = true,
  className,
  ...rest
}: ActiveStyledNavigationTileWithIconProps) => (
  <NavigationTileWithIcon showChevron={showChevron} activeClassName={className} {...rest} />
);
